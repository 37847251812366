import './CodeTable.css';
import { Component } from 'react';
import PropTypes from 'prop-types';
import { CODE_MAPS } from "../util/codeMaps";

class CodeTable extends Component {
    render() {
        const { rows, codeMapName } = this.props;
        return (
            <table>
                <thead>
                    <tr>
                        <th>Code</th>
                        { CODE_MAPS[codeMapName].attributes.map(attr => <th key={attr.name}>{attr.label}</th>) }
                    </tr>
                </thead>
                <tbody>
                    { rows.sort((a, b) => a.key.localeCompare(b.key))}
                </tbody>
            </table>
        );
    }
}

CodeTable.propTypes = {
    codeMapName: PropTypes.string.isRequired,
    rows: PropTypes.array,
}
export default CodeTable;
