import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import { cognitoUserPoolId, cognitoUserPoolClientId, restApiEndpoint, restApiName } from './config';

import { Amplify, Auth } from 'aws-amplify';

Amplify.configure({
    aws_user_pools_id: cognitoUserPoolId,
    aws_user_pools_web_client_id: cognitoUserPoolClientId,
    API: {
        endpoints: [
            {
                name: restApiName,
                endpoint: restApiEndpoint,
                custom_header: async () => {
                    return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
                }
            }
        ]
    }
});

ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  document.getElementById('root')
);
