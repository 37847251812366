export const ATTR_TYPE_PARSERS = {
  "N": str => parseInt(str, 10),
  "BOOL": str => 'true' === str,
}
export const SHIP_CODE_MAP_NAME = "ship_code";
export const CODE_MAPS = Object.freeze({
  ship_code: {
    label: "Ship Codes",
    attributes: [
      { name: "c", label: "Carrier" },
      { name: "m", label: "Method" },
      { name: "dta", label: "Days to Arrive (Est.)", type: "N",
        validator: (input) => input?.match(/^[0-9]{1,3}$/g) ? [] : [`"Days to Arrive (Est.)" field must contain 1-3 digits`] },
    ],
  },
  cancel_code: {
    label: "Cancel Codes",
    attributes: [
      { name: "reason", label: "Reason" },
      { name: "type", label: "Sender Account Type",
        validator: (input) => ["supplier", "retailer"].includes(input) ? [] : [`"Sender Account Type" field must contain "supplier" or "retailer"`] },
      { name: "report", label: "Report?", type: "BOOL",
        validator: (input) => ["true", "false"].includes(input) ? [] : [`"Report?" field must contain "true" or "false"`] },
    ],
  },
  return_code: {
    label: "Return Codes",
    attributes: [
        { name: "reason", label: "Reason" },
        { name: "description", label: "Description" },
    ],
  },
});
