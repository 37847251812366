import './PromoteCodeForm.css'
import { Component } from 'react';
import { promoteCodes } from "../util/api";
import Modal from "react-modal";
import CodeTable from './CodeTable';
import { lowerEnvironment } from '../config';
import PropTypes from 'prop-types';
import { CODE_MAPS } from "../util/codeMaps";

const initialState = {
    isOpen: false,
    isLoading: true,
    confirmButtonDisabled: true,
    diffRows: [],
    errors: []
};

class PromoteCodesForm extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    promoteChanges = async () => {
        this.toggleModal();
        const dryRun = await promoteCodes(this.props.codeMapName, true);
        const diffRows = [];

        for (const diff of dryRun.diff) {
            let className = 'yellow';
            let element = diff.after;

            if (!diff.after) {
                className = 'red';
                element = diff.before;
            }
            else if (!diff.before) {
                className = 'green';
            }

            diffRows.push(
                <tr className={className} key={element.code}>
                    <td key="code">{ element.code }</td>
                    {
                        CODE_MAPS[this.props.codeMapName].attributes.map(attr =>
                                // stringify boolean values so they become visible
                                <td key={attr.name}>{ `${element[attr.name]}` }</td>
                        )
                    }
                </tr>
            );
        }

        if (diffRows.length) {
            this.setState({
                isLoading: false,
                diffRows: diffRows,
                confirmButtonDisabled: false
            });
        }
        else {
            this.setState({
                isLoading: false,
                errors: ['No changes to display!']
            });
        }
    }

    confirmPromoteChanges = async () => {
        await promoteCodes(this.props.codeMapName, false);
        this.setState(initialState);
        await this.props.refreshCodes();
    }

    toggleModal = () => {
        const { isOpen } = this.state;
        this.setState({
            isOpen: !isOpen,
            diffRows: initialState.diffRows
        });
    }

    render() {
        const { diffRows, errors, isLoading, isOpen, confirmButtonDisabled } = this.state;
        return (
            <div>
                <h2>Promote Changes From {lowerEnvironment} Environment</h2>
                Click Submit to preview changes.
                <br/>
                <button onClick={this.promoteChanges}>Submit</button>

                <Modal className='modal' isOpen={isOpen} shouldCloseOnOverlayClick={true} onRequestClose={this.toggleModal}>
                    <h2>Confirm Changes</h2>
                    <div>
                        Are you sure you want to promote ship codes from the <b>{lowerEnvironment} environment</b>?
                    </div>
                    <br/>
                    <span className='green'>add</span><span className='yellow'>modify</span><span className='red'>delete</span>
                    { isLoading ? <div className='spinner'/> : <CodeTable rows={diffRows} codeMapName={this.props.codeMapName}/> }
                    <div>{ errors }</div>
                    <div>
                        <button className='modal-cancel-button' onClick={this.toggleModal}>Cancel</button>
                        <button className='modal-confirm-button' disabled={confirmButtonDisabled} onClick={this.confirmPromoteChanges}>Confirm</button>
                    </div>
                </Modal>
            </div>
        )
    }
}

PromoteCodesForm.propTypes = {
    codeMapName: PropTypes.string.isRequired,
    refreshCodes: PropTypes.func.isRequired,
}
export default PromoteCodesForm;
