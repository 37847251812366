import './PutCodeForm.css';
import { Component } from 'react';
import { putCode } from '../util/api';
import CodeTable from './CodeTable';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { CODE_MAPS } from "../util/codeMaps";

const initialState = (props) => {
    const attributes = {};
    CODE_MAPS[props.codeMapName].attributes.forEach(attr => {
        attributes[attr.name] = "";
    });
    return {
        record: {
            code: "",
            ...attributes,
        },
        isOpen: false,
        validationErrors: []
    };
};

class PutCodeForm extends Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
    }

    toggleModal = (event) => {
        const { isOpen } = this.state;
        this.setState({ isOpen: !isOpen });
    }

    handleInputChange = (event) => {
        this.setState(prevState => ({
            record: {
                ...prevState.record,
                [event.target.name]: event.target.value,
            }
        }));
    }

    handleSubmit = (event) => {
        const sanitizedRecord = {};
        Object.entries(this.state.record).forEach(([key, value]) => {
            sanitizedRecord[key] = value?.trim();
        })
        const validationErrors = this.validateInput(sanitizedRecord);
        if (validationErrors.length) {
            const errors = [];
            validationErrors.forEach((error, i) => {
                errors.push(<div key={i}>Error: { error }</div>)
            });
            this.setState({validationErrors: errors});
        }
        else {
            this.setState({
                isOpen: true,
                record: sanitizedRecord,
            });
        }
        event.preventDefault();
    }

    confirmCodeSubmission = async (event) => {
        await putCode(this.props.codeMapName, this.state.record);
        this.setState(initialState(this.props));
        await this.props.refreshCodes();
    }

    cancelCodeSubmission = (event) => {
        this.setState({
            isOpen: false
        });
    }

    validateInput = (record) => {
        const missingFieldMessage = 'field must not be blank';
        const codeRegex = /^[A-Z0-9_]{2,16}$/g;
        const errors = [];

        if (!record.code.match(codeRegex)) {
            errors.push('"Code" field must contain 2-16 uppercase characters, digits, and underscores');
        }

        Object.entries(record).forEach(([name, value]) => {
            if (name !== "code") {
                const attr = CODE_MAPS[this.props.codeMapName].attributes.find((attr) => attr.name === name);
                if (!value) {
                    errors.push(`"${attr.label}" ${missingFieldMessage}`);
                } else if (attr.validator) {
                    errors.push(...attr.validator(value));
                }
            }
        });

        return errors;
    }

    createPutCodeRows = () => {
        return [(
            <tr key="confirmation">
                <td>{ this.state.record.code }</td>
                {
                    CODE_MAPS[this.props.codeMapName].attributes.map(attr =>
                            <td key={attr.name}>{ this.state.record[attr.name] }</td>
                    )
                }
            </tr>
        )];
    }

    createPutCodeInputRows = () => {
        return [(
            <tr key="input">
                <td><input name="code" type="text" value={this.state.record.code} onChange={this.handleInputChange}/></td>
                {
                    CODE_MAPS[this.props.codeMapName].attributes.map(attr =>
                        <td key={attr.name}>
                            <input name={attr.name} type="text" value={this.state.record[attr.name]} onChange={this.handleInputChange}/>
                        </td>
                    )
                }
            </tr>
        )];
    }

    render() {
        return (
            <div>
                <h2>Create/Update Record</h2>
                <form onSubmit={this.handleSubmit}>
                    <div className='validation-errors'>
                        { this.state.validationErrors }
                    </div>
                    <label>
                        <CodeTable rows={this.createPutCodeInputRows()} codeMapName={this.props.codeMapName}/>
                    </label>
                    <div>
                        <input className='create-update-submit-input' type="submit" value="Submit" />
                    </div>
                </form>

                <Modal className='modal' isOpen={this.state.isOpen} shouldCloseOnOverlayClick={true} onRequestClose={this.toggleModal}>
                    <h2>Confirm Changes</h2>
                    <div>
                        Are you sure you want to submit these changes?
                        <CodeTable rows={this.createPutCodeRows()} codeMapName={this.props.codeMapName}/>
                        <br/>
                    </div>
                    <div>
                        <button className='modal-cancel-button' onClick={this.cancelCodeSubmission}>Cancel</button>
                        <button className='modal-confirm-button' onClick={this.confirmCodeSubmission}>Confirm</button>
                    </div>
                </Modal>
            </div>
        );
    }
}

PutCodeForm.propTypes = {
    codeMapName: PropTypes.string.isRequired,
    refreshCodes: PropTypes.func.isRequired,
}
export default PutCodeForm;
