import { API } from 'aws-amplify';
import { restApiName } from '../config';
import { ATTR_TYPE_PARSERS, CODE_MAPS } from "../util/codeMaps";

export const getCodes = async (codeMapName) => {
    const response = await API.get(restApiName, `/codes/${codeMapName}`, {});
    return response.codes;
};

export const putCode = async (codeMapName, record) => {
    const params = {
        body: {},
    }
    CODE_MAPS[codeMapName].attributes.forEach(attr => {
        const parser = ATTR_TYPE_PARSERS[attr.type];
        params.body[attr.name] = parser ? parser(record[attr.name]) : record[attr.name];
    });
    await API.put(restApiName, `/codes/${codeMapName}/${record.code}`, params);
};

export const promoteCodes = async (codeMapName, dryRun = true) => {
    const params = {
        body: { dryRun }
    };
    return await API.post(restApiName, `/codes/${codeMapName}/promote`, params);
}
