import './App.css';
import {Component} from 'react';
import {withAuthenticator} from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css';
import PutCodeForm from './PutCodeForm';
import CodeTable from './CodeTable';
import PromoteCodesForm from './PromoteCodesForm';
import {environment, lowerEnvironment} from '../config';
import {getCodes} from "../util/api";
import {SHIP_CODE_MAP_NAME, CODE_MAPS} from "../util/codeMaps";
import {CSVLink} from 'react-csv';



class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCodeMapName: SHIP_CODE_MAP_NAME,
            codeRows: [],
            errorMessage: "",
            loading: true
        };
    }

    handleCodeMapChange = async (event) => {
        const selectedCodeMapName = event.target.value;
        this.setState({ selectedCodeMapName });
        // Can't assume the state update has happened yet, so override
        await this.refreshCodes(selectedCodeMapName);
    }

    // default parameter to simplify invocation from other components receiving a function prop
    refreshCodes = async (selectedCodeMapName = this.state.selectedCodeMapName) => {
        this.setState({
            codeRows: [],
            loading: true,
            csvRows: []
        });
        const rows = [];
        const csvHeader = [selectedCodeMapName, ...CODE_MAPS[selectedCodeMapName].attributes.map(attr => attr.label)];
        const csvArr =  [];
        let errorMessage = "";
        try {
            const codes = await getCodes(selectedCodeMapName);
            Object.entries(codes).forEach(([code, codeMetadata]) => {
                csvArr.push([code, ...CODE_MAPS[selectedCodeMapName].attributes.map(attr => codeMetadata[attr.name])])
                rows.push(
                    <tr key={code}>
                        <td>{ code }</td>
                        {
                            CODE_MAPS[selectedCodeMapName].attributes.map(attr =>
                                // stringify boolean values so they become visible
                                <td key={attr.name}>{`${codeMetadata[attr.name]}`}</td>
                        )}
                    </tr>
                );
            });
        } catch(err) {
            const detailMessage = err.response?.data?.error?.message;
            errorMessage = detailMessage;
        }
        this.setState({
            loading: false,
            errorMessage,
            codeRows: rows,
            csvRows: [csvHeader, ...csvArr.sort()]
        });
    }

    async componentDidMount() {
        await this.refreshCodes(this.state.selectedCodeMapName);
    }

    render() {
        return (
            <div className="App">
                <h1>Environment: { environment.toUpperCase() }</h1>
                <header className="App-header">
                    <h2>Code Map Selection</h2>
                    <select value={this.state.selectedCodeMapName} onChange={this.handleCodeMapChange}>
                        {Object.entries(CODE_MAPS).map(([codeMapName, codeMapContent]) =>
                            <option key={codeMapName} value={codeMapName}>{codeMapContent.label}</option>
                        )}
                    </select>
                </header>
                {
                    this.state.errorMessage ?
                        <span className="error">{this.state.errorMessage}</span> :
                        <>
                            {
                                // lowerEnvironment identifies the env lower than the current one, not that the current one is a lower
                                (lowerEnvironment ?
                                    // key must change when selectedCodeMapName changes to cause input state to reset 
                                    <PromoteCodesForm refreshCodes={this.refreshCodes} 
                                        codeMapName={this.state.selectedCodeMapName} 
                                        key={"form_" + this.state.selectedCodeMapName}
                                    /> :
                                    <PutCodeForm refreshCodes={this.refreshCodes} 
                                        codeMapName={this.state.selectedCodeMapName} 
                                        key={"form_" + this.state.selectedCodeMapName}
                                    />
                                )
                            }
                            <hr/>
                            <h2>Current Records</h2>
                            {
                                this.state.loading ?
                                    <div className='spinner'/> :
                                        <>
                                        <CodeTable rows={this.state.codeRows} codeMapName={this.state.selectedCodeMapName}/>
                                        <CSVLink
                                            className="create-update-submit-input"
                                            enclosingCharacter={`"`}
                                            filename={`${this.state.selectedCodeMapName}_${environment}_${Date.now()}.csv`}
                                            data={this.state.csvRows}>
                                            Export to CSV
                                        </CSVLink>
                                        </>
                            }
                        </>
                }
            </div>
        );
    }
}

export default withAuthenticator(App, {hideSignUp:true});
